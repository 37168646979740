<template>
    <div class="FilesBox" @keyup.ctrl="test" @scroll="handleScroll()">
        <div style="width:100%;" ref="companyStyle">
            <div class="line" style="height: 58px;">
                <div class="input_box">
                    <a-select
                            show-search
                            v-model="searchValue"
                            placeholder="搜索姓名"
                            style="width: 100%"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @search="searchUser"
                            @change="selectedUser">
                        <a-select-option v-for="(it,index) in userList" v-model:fieldNames="it.Id" v-model:value="it.Id"  v-model:label="it.Name">
                            {{ it.Name }}
                        </a-select-option>
                    </a-select>
                    <a-icon type="search" @click="onSearchFile" class="btn" />
                </div>
            </div>
            <a-table :columns="columns" :data-source="data" bordered>
                <a slot="name" slot-scope="text">{{ text }}</a>
                <span slot="customTitle"><a-icon type="smile-o" /> 姓名</span>
                <span slot="action" slot-scope="key">
                    <a-button type="danger" @click="showDrawer(key)"> 交接管理 </a-button>
                 </span>
            </a-table>
        </div>
        <a-drawer
                    title="项目交接"
                    :width="720"
                    :visible="visible"
                    :body-style="{ paddingBottom: '80px' }"
                    @close="onClose"
            >
            <a-space  v-for="item in items" align="baseline" >
                <a-form :form="form" layout="vertical" hide-required-mark>
                    <a-row :gutter="16">
                        <a-col :span="6">
                            <a-form-item label="项目名称">
                                <a-input v-model:value="item.Name" placeholder="Please enter user name"  disabled/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-item label="执行方法">
                                <a-input  v-model:value="item.Methods"  placeholder="Please enter user name" disabled/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-item label="交接负责人">
                                <a-select
                                        show-search
                                        v-model="item.Receiver"
                                        placeholder="姓名"
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :filter-option="false"
                                        :not-found-content="null"
                                        @search="handleSearch"
                                        @change="handleChange">
                                    <a-select-option v-for="(user,index) in list" v-model:value="user.Name"  v-model:label="user.Name">
                                        {{ user.Name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-item label="操作">
                                <a-button type="primary" @click="submit(item)">
                                    提交
                                </a-button>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </a-space>
            </a-drawer>
    </div>
</template>
<script>
    import {message} from "ant-design-vue";

    const columns = [
        {
            dataIndex: 'Name',
            key: 'Name',
            slots: { title: 'customTitle' },
            scopedSlots: { customRender: 'name' },
        },
        {
            title: '工号',
            dataIndex: 'Num',
            key: 'Num',
        },
        {
            title: '部门',
            dataIndex: 'DepartmentStr',
            key: 'DepartmentStr',
        },
        {
            title: '离职日期',
            key: 'OffDate',
            dataIndex: 'OffDate',
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];
    export default {
        name: "SetHandOver",
        components: {  },
        data() {
            return {
                isLoading:true,
                searchValue:'',
                columns,
                data:[],
                form: this.$form.createForm(this),
                visible: false,
                searchVal:'',
                items:[],
                list:[],
                value:'',
                userList:[],
                currentId:'',
            };
        },
        created() {
        },

        mounted() {

        },

        methods: {
            selectedUser(value){
                this.searchValue = value;
            },
            //筛选用户
            searchUser(value){
                this.$axios.get(
                    8028,
                    {name:value,UserState:1} ,
                    res => {
                        if (res.data.code == 1) {
                            this.userList = res.data.data;
                        }
                    }
                )
            },
            submit(item){
                this.$axios.post(
                    13005,
                    {User_id:this.currentId,Receiver:item.Receiver,Item_id:item.Id } ,
                    res => {
                        if (res.data.code == 1) {
                            message.success({
                                content: () => '提交成功',
                                class: 'custom-class',
                                style: {
                                    marginTop: '50vh',
                                },
                            });
                        }
                    }
                )
            },
            handleSearch(value){
                this.$axios.get(
                    8028,
                    {name:value,UserState:0} ,
                    res => {
                        if (res.data.code == 1) {
                            this.list = res.data.data;
                        }
                    }
                )
            },
            handleChange(value){
                console.log(value);
            },
            //搜索用户
            onSearchFile(){
                this.$axios.get(
                    8028,
                    {name:this.searchValue} ,
                    res => {
                        if (res.data.code == 1) {
                            this.data = res.data.data;
                        }
                    }
                )
            },
            //弹出选择框
            showDrawer(key) {
                this.currentId = key.Id;
                this.$axios.get(
                    13004,
                    {user_id:key.Id} ,
                    res => {
                        if (res.data.code == 1) {
                            this.isLoading = true;
                            this.items = res.data.data;
                            this.visible = true;
                        }
                    }
                )
            },
            onClose() {
                this.visible = false;
            }
        }
    };
</script>
<style lang="less" scoped>
    .FilesBox {
        position: relative;
        background: #fff;
        border-radius: 4px;

        display: flex;
        overflow-y: auto;
        user-select: none;
        font-size: 14px;
        color: #666666;
        .mr-2 {
            margin-right: 16px;
        }
        .bgHover {
            background-color: #f8f9fa;
        }
        th.column-money,
        td.column-money {
            text-align: right !important;
        }
        .border-r-4 {
            text-indent: 30px;
        }
        .title {
            color: #333333;
            font-size: 18px;
        }
        .link-label {
            color: #333333;
            font-size: 18px;
            display: inline-block;
            height: 100%;
            text-indent: 0px;
        }
        .gengduo {
            position: relative;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            text-align: center;
            line-height: 36px;
            &:hover {
                background-color: #f8f9fa;
            }
        }
        .link-label:hover {
            cursor: pointer;
            color: #333333;
        }
        .input_box {
            height: 38px;
            width: 234px;
            margin: 10px 18px;
            display: inline-block;
            position: relative;
            input{
                width:85%;
                height:100%;
                margin-left: 16px;
                background: transparent;
                border: 0px solid transparent;
                outline: none;
            }
            .btn{
                position: absolute;
                right: 16px;
                top: 9px;
                font-size: 18px;
            }
        }
    }
</style>
