<template>
    <div>
    <a-form ref="formRef" @finish="onFinish" style="margin-top: 20px;margin-left: 20px;">
        <div  style="display: flex; margin-bottom: 20px;text-align: center;font-weight: bold" align="baseline"  >
            <div>   <a-tag color="#88cf17" style="font-weight: bold">角色列表 </a-tag></div>
            <div style="margin-left: 147px;">   <a-tag color="#41cdf7" style="font-weight: bold">绑定项目 </a-tag></div>
        </div>
        <a-space v-for="(item,index) in list" style="display: flex; margin-bottom: -16px" align="baseline" >
            <a-form-item>
                <a-select v-model="item.role_value"  style="min-width: 150px;"  placeholder="请选择"    option-label-prop="value">
                    <a-select-option v-model:value="role.Id" v-model:label="role.title" v-for="role in roles"> {{role.title}} </a-select-option>
                </a-select>
            </a-form-item>
            <a-icon type="arrow-right" style="position: relative;top: 25px;left: 20px;" />
            <a-form-item style="margin-left: 40px">
                <a-select  v-model="item.item_value"  mode="multiple"  style="min-width: 150px;" placeholder="请选择"    option-label-prop="value">
                    <a-select-option v-model:value="item.Id"  v-model:label="item.Name" v-for="item in items"> {{item.Name}} </a-select-option>
                </a-select>
            </a-form-item>
        </a-space>
        <a-form-item>
        <a-tag color="#f34040" style="font-weight: bold"  @click="addUser">+增加配置</a-tag>
        </a-form-item>

        <a-form-item>
            <a-button type="primary" @click="submit">提交</a-button>
        </a-form-item>
    </a-form>
    </div>
</template>
<script>
    import { message } from 'ant-design-vue';
    export default {
        name: 'SetItem',
        data(){
            return {
                formRef:[],
                items:[],
                roles:[],
                list:[
                    {item_value:[],role_value: []}
                ],

            }

        },

        created() {
            this.handoverItems()
            this.getRoles()
            this.handoverRoles()
        },
        methods:{
            handoverRoles(){
                this.$axios.get(
                    13003,
                    {} ,
                    res => {
                        if (res.data.code == 1) {
                            this.list = res.data.data;
                        }
                    }
                )
            },
            getRoles(){
                this.$axios.get(
                    8027,
                    {} ,
                    res => {
                        if (res.data.code == 1) {
                            this.roles = res.data.data;
                            console.log(this.roles)
                        }
                    }
                )
            },
            handoverItems(){
                this.$axios.get(
                    13001,
                    {} ,
                    res => {
                        if (res.data.code == 1) {
                            this.items = res.data.data;
                        }
                    }
                )
            },
            removeUser(item){
                    this.items.splice(item, 1);
            },
           addUser() {
               this.list.push({item_value:[],role_value: []});
            },
             onFinish (){
               // console.log(values)
            },
            //提交保存|更新|项目
            submit () {
                console.log(this.list)
                this.$axios.post(
                    13002,
                    {data:JSON.stringify(this.list) } ,
                    res => {
                        if (res.data.code == 1) {
                            this.items = res.data.data;
                            message.success({
                                content: () => '保存成功',
                                class: 'custom-class',
                                style: {
                                    marginTop: '50vh',
                                },
                            });
                        }
                    }
                )
            },
        }

    }
</script>