<template>
    <div>
    <a-form ref="formRef" @finish="onFinish" style="margin-top: 20px;margin-left: 20px;">
        <div  style="display: flex; margin-bottom: 20px;text-align: center;font-weight: bold" align="baseline"  >
            <div>项目名</div>
            <div style="margin-left: 162px;">执行方法</div>
        </div>
            <a-space  v-for="(item,index) in items"  style="display: flex; margin-bottom: -8px;" align="baseline" >
                    <a-form-item name="[name]" >
                         <a-input v-model:value="item.Name" placeholder="项目名" />
                    </a-form-item>
                    <a-form-item  name="[value]">
                        <a-input v-model:value="item.Methods" placeholder="执行方法"/>
                    </a-form-item >
                    <a-form-item ><a-icon type="minus-circle" @click="removeUser(index)"/></a-form-item>
            </a-space>
        <a-form-item>
            <a-tag color="#f34040" style="font-weight: bold"  @click="addUser">+增加项目</a-tag>
        </a-form-item>
        <a-form-item>
            <a-button type="primary" @click="submit">提交</a-button>
        </a-form-item>
    </a-form>
    </div>
</template>
<script>
    import { message } from 'ant-design-vue';
    export default {

        data(){
            return {
                formRef:[],
                items:[],
                value: ['客服员'],
            }

        },
        created() {
            this.handoverItems()
        },
        methods:{
            handoverItems(){
                this.$axios.get(
                    13001,
                    {} ,
                    res => {
                        if (res.data.code == 1) {
                            this.items = res.data.data;
                        }
                    }
                )
            },
            removeUser(item){
                    this.items.splice(item, 1);
            },
           addUser() {
               this.items.push({
                    Name: '',
                    Methods: '',
                    Id: '',
                });
            },
             onFinish (){
               // console.log(values)
            },
            //提交保存|更新|项目
            submit () {
                console.log(this.items)
                this.$axios.post(
                    13000,
                    {data:JSON.stringify(this.items) } ,
                    res => {
                        if (res.data.code == 1) {
                            this.items = res.data.data;
                            message.success({
                                content: () => '保存成功',
                                class: 'custom-class',
                                style: {
                                    marginTop: '50vh',
                                },
                            });
                        }
                    }
                )
            },
        }

    }
</script>