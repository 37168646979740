<template>
  <div class="HomeWork">
    <div class="tabs">
      <ul>
        <li @click="_tabsActive(0)" :class="tabsActive == 0 ? 'active' : ''">
          基础配置
        </li>
          <li @click="_tabsActive(1)" :class="tabsActive == 1 ? 'active' : ''">
              项目设置
          </li>
         <li @click="_tabsActive(2)" :class="tabsActive == 2 ? 'active' : ''">
          交接设置
        </li>
      </ul>
    </div>
    <div class="container">
        <div class="content" v-show="tabsActive == 2"  style="padding: 0; border: none;margin-left: 0;background: white;">
            <set-hand-over></set-hand-over>
        </div>
        <div class="content" v-show="tabsActive == 1"  style="padding: 0; border: none;margin-left: 0;background: white;">
          <SetItem></SetItem>
        </div>
      <!--模板库-->
        <div class="content" v-show="tabsActive == 0"  style="padding: 0; border: none;margin-left: 0;background: white;">
        <item></item>
        </div>
    </div>
  </div>
</template>

<script>
import item from "./item"
import SetItem from "./SetItem";
import SetHandOver from "./SetHandOver";
export default {
  name: 'HomeWork',
  components: {SetHandOver, SetItem, item },
  data () {
    return {
      tabsActive: 0
    }
  },
  created () {
    //获取素材分类
    this.$axios.get(1206, { label: 'homeWorkMaterial' }, res => {
      if (res.data.code == 1) {
        this.materialList = res.data.data
      }
    })
    //获取素材列表
    this.$axios.get(5836125, { CategoryId: '', limit: 20, page: 1 }, res => {
      if (res.data.code == 1) {
        this.materiaData = res.data.data
      }
    })
    //如果是图形填空，获取高级设置信息
  },
  mounted () {
  },
  methods: {

    _tabsActive (index) {
      this.tabsActive = index
      this.selectTemplateId = ''
      this.setWorkBook = -1
      this.isNew = false
      if (index == 2) {

        // this._workBookInfo()
      }
    },

  }
}
</script>

<style lang="less" scoped>
.HomeWork {
  height: 100%;
  .tabs {
    border-bottom: 1px solid #ccd1dc;
    border-left: 1px solid #ccd1dc;
    border-top-left-radius: 5px;
    ul {
      li {
        display: inline-block;
        width: 85px;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #ccd1dc;
        border-bottom: none;
        cursor: pointer;
        border-left: 0;
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 5px;
        }
      }
      .active {
        background: #1890ff;
        color: #fff;
        border: 1px solid #1890ff;
      }
    }
  }
}

.container {
  min-height: calc(~'100% - 60px');
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .content {
    flex: 1;
    margin-left: 15px;
    padding: 15px;
    padding-bottom: 40px;
    border: 1px solid #dfe0e6;
    background: #fff;
    border-radius: 5px;
    .row-title {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      span {
        font-size: 16px;
      }
    }

    .template-list {
      padding-top: 15px;
      .search-div {
        .condition {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 10px;
          span {
            margin-right: 10px;
          }
        }
      }
      .icon-btn {
        font-size: 20px;
        margin-right: 8px;
        cursor: pointer;
      }

      .title-row {
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          width: 140px;
          margin-right: 10px;
          margin-bottom: 15px;
          .template-img {
            height: 100px;
            overflow: hidden;
            position: relative;
          }
          .template-title {
            padding: 15px;
            padding-bottom: 0;
            font-size: 16px;
            color: #2d3e5d;
            span {
              display: inline-block;
              width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .created-date {
            padding: 0 15px;
            padding-bottom: 10px;
            color: #999;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .pagination {
        text-align: right;
      }
    }
    .template-library {
      .title-row {
        > li {
          width: 200px;
          margin-right: 15px;
          margin-bottom: 15px;
          .template-img {
            height: 160px;
          }
        }
      }
    }
  }
  .tree {
    width: 200px;
    padding: 15px;
    border: 1px solid #dfe0e6;
    border-radius: 5px;
    background: #fff;
    .tree-title {
    }
  }
}

.status-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
  margin-right: 3px;
}

/*
    *添加题目模板弹出框样式
    */
.parameter {
  position: relative;
  width: 350px;
  padding: 0 20px;
  border-left: 8px solid #f9f9fb;
  .float {
    position: fixed;
  }
  .parameter-title {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    background: #666668;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  .item-row {
    margin-bottom: 15px;
    .row-label {
      margin-bottom: 10px;
    }
    .row-input {
      position: relative;
      .association-img {
        width: 70px;
        height: 70px;
      }
      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
      }
      .icon-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
      }
      .add-association {
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 70px;
        border: 1px dashed #e3e2e5;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          margin-top: -15px;
          font-size: 50px;
          color: #aaa;
          font-weight: 300;
        }
        div {
          margin-top: -15px;
          font-size: 12px;
          color: #aaa;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .item-row-checkbox {
    margin-bottom: 20px;
  }
}

.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .video {
    position: relative;
    border: 1px solid #eee;
    .video-msg {
      padding: 10px;
      border-top: 1px solid #f3f1f3;
      .video-name {
        font-size: 16px;
      }
      .video-length {
        color: #aaa;
        font-size: 14px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      max-height: 165px;
    }
    .play-btn {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #666;
      cursor: pointer;
    }
  }
  .audio {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    .audio-title {
      position: relative;
      flex: 1;
      .audio-upload-time {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #aaa;
      }
    }
    .audio-file {
      width: 80px;
      padding: 10px 0;
      background: #fafafc;
      text-align: center;
      margin-left: 10px;
    }
    .audio-length {
      color: #aaa;
      font-size: 14px;
    }
  }
  .title-row-label {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
  }
  .advanced-setup {
    padding: 5px 15px;
    border: #d9d9d9 1px solid;
    border-radius: 4px;
    position: relative;
    top: 110px;
    left: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .advanced-setup:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    cursor: pointer;
  }
  .title-row-input {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    .select-input {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      span {
        color: #ddd;
      }
    }
    .upload-attachment {
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .audio,
    .video {
      margin-top: 15px;
    }
  }
}

.template-thumbnail {
  position: absolute;
  top: 15px;
  right: 15px;
  ul {
    li {
      display: inline-block;
      width: 150px;
      height: 150px;
      margin-right: 20px;
      border: 1px solid #e4e3ea;
      border-radius: 5px;
      text-align: center;
      vertical-align: top;
      p {
        margin-top: 5px;
        text-align: center;
        color: #666;
        font-size: 12px;
      }
      img {
        display: inline-block !important;
        margin: 10px 20px;
        width: 85px;
        height: 105px;
      }
      #qrcode {
        margin: 0 auto;
        margin-top: 15px;
        width: 90px;
        height: 90px;
        img {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.question-msg {
  .title-row-input {
    ul {
      li {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #e4e4e4;
        vertical-align: top;
        .operation-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #000;
          opacity: 0.7;
          color: #fff;
          display: flex;
          align-items: center;
          span {
            position: relative;
            display: inline-block;
            flex: 1;
            padding: 4px 0;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
        .itme-empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          width: 100%;
          text-align: center;
          color: #aaa;
        }
        &.add-btn {
          border: 1px dashed #e4e4e4;
          font-size: 40px;
          color: #e4e4e4;
          text-align: center;
          span {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 90px;
            line-height: 1;
            cursor: pointer;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        &.active {
          border: 1px dashed #1890ff;
        }
      }
    }
    .add-row-btn {
      padding: 8px 0;
      border: 1px solid #ececed;
      text-align: center;
      color: #aaa;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .question-msg-title {
    margin-bottom: 15px;
    .label-del {
      margin-left: 15px;
      color: #b6b3b6;
      font-size: 12px;
    }
  }
}

/*
    *上传模板弹出框样式
    */
.label-input {
  margin-top: 5px;
  margin-bottom: 15px;
}

.template-upload-btn {
  position: relative;
  border-color: #1890ff;
  color: #1890ff;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.upload-suggestion {
  color: #a6a5a8;
  font-size: 12px;
}

.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}

.upload-btn {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.ant-drawer-body {
  height: calc(~'100% - 55px');
}

/*
    *练习册详情
    */
.workBook-detail {
  width: 100%;
  .tree {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 100%;
    .row {
      display: flex;
      align-items: center;
      margin: 20px 0;
      p {
        width: 70px;
        color: #abb0bf;
      }
      input {
        flex: 1;
      }
    }
  }
}

.crumbs {
  position: relative;
  height: 64px;
  padding-right: 30px;
  line-height: 64px;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  color: #666;
  font-size: 14px;
  .return {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #9d9d9d;
    cursor: pointer;
  }
  .crumbs-icon {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    color: #9d9d9d;
  }
  .crumbs-item {
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
  .plptext {
    display: inline-block;
    width: 100px;
    height: auto;
    color: #c2c2c2;
  }
}

#advanced-setup-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 99999;
  .box-bgcolor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
  }
  .image-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1024px;
    height: 100%;
    background-color: #fff;
    z-index: 100000;
    .btn-box {
      width: 100%;
      height: 70px;
      line-height: 70px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: solid 1px #e3e3e3;
      span {
        border: #e3e3e3 1px solid;
        padding: 7px 20px;
        margin-left: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .video-box {
      position: relative;
      height: 667px;
      width: 100%;
      .subject-box {
        position: relative;
        height: 100%;
        width: 375px;
        border: #e3e3e3 1px solid;
        margin: 0 50px;
        box-shadow: rgba(207, 207, 207, 0.5) 0px 0px 12px 2px;
        p {
          font-size: 17px;
          text-indent: 20px;
        }
        .subject-images {
          width: 90%;
          height: auto;
          position: relative;
          // top: 60px;
          left: 50%;
          transform: translate(-50%, 0%);
        }
        .answer-box {
          width: 90%;
          height: 200px;
          position: relative;
          // top: 55%;
          left: 50%;
          transform: translate(-50%, 0%);
          img {
            position: absolute;
            height: auto;
            margin-right: 10px;
          }
          .sub-box {
            position: relative;
            img {
              position: absolute;
              z-index: 100005;
            }
          }
          // .ans-box {
          //   margin-top: 20px;
          //   img {
          //     position: relative;
          //     top: 50px;
          //   }
          // }
        }
      }
      .position-data-box {
        position: absolute;
        right: 224px;
        top: 0px;
        width: 300px;
        height: 100%;
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            margin-top: 10px;
            p {
              input {
                width: 50px;
                margin-top: 5px;
                box-shadow: none;
                border: #e3e3e3 1px solid;
              }
              button {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                border: #e3e3e3 1px solid;
                background-color: #fff;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
